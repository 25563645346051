<!--  -->
<template>
  <div class="index">
    <el-dialog
      title="修改头像"
      :visible.sync="dialogFormVisible"
      @close="userClose()"
    >
      <el-form :model="form" v-if="type == 1">
        <el-form-item label="修改密码:" :label-width="formLabelWidth">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <el-upload
        v-if="type == 2"
        :headers="token"
        accept=".jpg,.png"
        :on-remove="imgRemove"
        :limit="1"
        :action="imgUrl"
        list-type="picture"
        :on-exceed="exceed"
        :on-success="imgSuccess"
        name="image"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">上传头像</el-button>
      </el-upload>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPassword" v-if="type == 1"
          >确 定</el-button
        >
        <el-button type="primary" @click="editPassword" v-if="type == 2"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="pwDialogVisible"
      @before-close="confirmClose()"
    >
      <el-form ref="pwref" :model="pwform" :rules="pwrules">
        <el-form-item label="原密码" label-width="120px" prop="password">
          <el-input v-model="pwform.password" :type="pwdType">
            <i slot="suffix" class="el-icon-view" @click="showPwd"></i
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="120px" prop="new_password">
          <el-input v-model="pwform.new_password" :type="pwdType1">
            <i slot="suffix" class="el-icon-view hahaha" @click="showPwd1"></i>
          </el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码"
          label-width="120px"
          prop="confirm_password"
        >
          <el-input v-model="pwform.confirm_password" :type="pwdType2">
            <i slot="suffix" class="el-icon-view gagaga" @click="showPwd2"></i
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changepw()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="通知详情" :visible.sync="dialogVisible" width="30%">
      <table class="tab">
        <tr>
          <td class="tab-tit">通知标题</td>
          <td>{{ info.name }}</td>
        </tr>
        <tr>
          <td class="tab-tit">发出人</td>
          <td>{{ info.u_name }}</td>
        </tr>
        <tr>
          <td class="tab-tit">接收人</td>
          <td>{{ info.username }}</td>
        </tr>
        <tr>
          <td class="tab-tit">通知内容</td>
          <td>{{ info.content }}</td>
        </tr>
      </table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-card>
      <el-tabs :tab-position="tabPosition" style="height: 500px">
        <el-tab-pane label="账号设置">
          <div style="padding: 8px 40px">
            <div class="title">安全设置</div>
            <div class="info">
              <div class="info-left">
                <div class="left-top">
                  <i class="el-icon-user"> 账号安全</i>
                </div>
                <div class="left-bom">
                  <el-button
                    type="success"
                    size="medium"
                    @click="changePassWord(2)"
                    >修改密码</el-button
                  >
                  <el-button
                    type="warning"
                    size="medium"
                    @click="changePassWord(1)"
                    >重置密码</el-button
                  >
                </div>
              </div>
              <div class="info-right">
                <!-- <el-button @click=";(dialogFormVisible = true), (type = 1)"
                  >修改</el-button
                > -->
              </div>
            </div>
            <div class="info">
              <div class="info-left">
                <div class="left-top">
                  <i class="el-icon-picture-outline"> 个性设置</i>
                </div>
                <div class="left-bom">
                  <el-button
                    type="success"
                    size="medium"
                    @click="getUinfo(true)"
                    >修改头像</el-button
                  >
                </div>
              </div>
              <div class="info-right"></div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="其他设置">
          <div style="padding: 8px 40px">
            <div class="title">其他设置</div>
            <el-button
              @click="
                downloadIamge(
                  'http://sysapp.campgrounds.cn/public/getfile393',
                  '图片'
                )
              "
            >
              下载
            </el-button>
            <el-rate
              v-model="value"
              show-score
              :max="6"
              :allow-half="false"
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的通知">
          <div style="padding: 8px 40px">
            <div class="title">我的通知</div>
            <el-table :data="tableData" style="width: 100%" height="400px">
              <el-table-column prop="id" label="任务ID"> </el-table-column>
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="u_name" label="发起人"> </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  <el-tag type="success" v-if="scope.row.state == 2"
                    >已读</el-tag
                  >
                  <el-tag type="info" v-if="scope.row.state == 1">未读</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    style="margin-right: 10px"
                    size="mini"
                    icon="el-icon-search"
                    @click="infoJus(scope.row, form)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              @current-change="pageChange"
              @size-change="sizeChange"
              :page-sizes="[7, 15, 20, 50]"
              layout="total, sizes, prev, pager, next"
              :page-size="this.getInfo.page_num"
              background
              :pager-count="15"
              :total="exp.num"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import {
  getInform,
  getUppass,
  getInfo,
  getHeadPortrait,
  getUserInfo,
  changePw,
} from '@/api/mine'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Breadcrumb },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value !== this.pwform.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    //这里存放数据
    return {
      value: 5,
      type: 1,
      dialogVisible: false,
      info: {},
      fileList: [],
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      pwDialogVisible: false,
      info: {},
      tableData: [],
      dialogFormVisible: false,
      getNav: [{ path: '', name: '个人设置' }],
      tabPosition: 'left',
      pwdType: 'password',
      pwdType1: 'password',
      pwdType2: 'password',
      form: {
        password: '',
        img: '',
      },
      formLabelWidth: '100px',
      pwform: {
        password: '',
        new_password: '',
        confirm_password: '',
      },
      pwrules: {
        password: [
          { required: true, message: '请输入原密码', trigger: 'change' },
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'change' },
        ],
        confirm_password: [
          { required: true, message: '请确认新密码', trigger: 'change' },
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    confirmClose() {
      this.$confirm('确认关闭对话框？')
        .then((_) => {
          closePwDialog()
          done()
        })
        .catch((_) => {})
    },
    closePwDialog() {
      this.pwform = {
        password: '',
        new_password: '',
        confirm_password: '',
      }
      this.$nextTick(() => {
        this.$refs.pwref.clearValidate()
      })

      this.pwDialogVisible = false
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function() {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
        let a = document.createElement('a') // 生成一个a元素
        let event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || 'photo' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
    down() {
      list.forEach((item) => {
        this.downs(item)
      })
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response)
      this.form.img = response.data.id
    },
    exceed() {
      this.$message({
        message: '上传图片数量超出',
        type: 'warning',
      })
    },
    //移除图片的事件
    imgRemove() {
      this.form.img = ''
      console.log(this.form)
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.getTableData()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.getTableData()
    },
    async getUinfo(type) {
      if (type) {
        this.type = 2
        this.dialogFormVisible = true
      }
      console.log(this.fileList)
      this.fileList = []
      let info = await getUserInfo()
      this.info = info.data.data
      this.fileList.push({ name: this.info.img.id, url: this.info.img.url })
      this.form.img = this.info.img.id
      this.$store.commit('setUserInfo', info.data.data)
      console.log(this.$store.state.info.img.url)
    },
    editJus(info) {},
    async infoJus(info) {
      this.dialogVisible = true
      let info2 = await getInfo({ id: info.id })
      this.info = info2.data.data
      this.getTableData()
    },
    async getTableData() {
      let info = await getInform(this.getInfo)
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableData = info.data.data
      console.log(this.exp)
    },
    userClose() {
      this.form = {
        password: '',
        img: '',
      }
      this.fileList = []
    },
    async editPassword() {
      let info
      if (this.type == 1) {
        info = await getUppass(this.form)
      } else {
        info = await getHeadPortrait(this.form)
      }
      if (info.data.code == 1) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        if (this.type == 2) {
          this.getUinfo()
          this.$EventBus.$emit('updateImg')
        }
        this.dialogFormVisible = false
        this.form.password = ''
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },

    async changePassWord(tag) {
      if (tag === 1) {
        // 重置密码
        this.$confirm('是否将密码重置为123456?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const res = await changePw({
            tag: 1,
          })
          console.log(res)
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '重置密码成功，请重新登录',
            })
            this.del()
          } else {
            this.$message.error(res.data.message)
          }
        })
      } else if (tag === 2) {
        this.pwDialogVisible = true
      }
    },
    showPwd() {
      this.pwdType === 'password'
        ? (this.pwdType = '')
        : (this.pwdType = 'password')
      let e = document.getElementsByClassName('el-icon-view')[0]
      this.pwdType == ''
        ? e.setAttribute('style', 'color: #409EFF')
        : e.setAttribute('style', 'color: #c0c4cc')
    },
    showPwd1() {
      this.pwdType1 === 'password'
        ? (this.pwdType1 = '')
        : (this.pwdType1 = 'password')
      let e = document.getElementsByClassName('hahaha')[0]
      this.pwdType1 == ''
        ? e.setAttribute('style', 'color: #409EFF')
        : e.setAttribute('style', 'color: #c0c4cc')
    },
    showPwd2() {
      this.pwdType2 === 'password'
        ? (this.pwdType2 = '')
        : (this.pwdType2 = 'password')
      let e = document.getElementsByClassName('gagaga')[0]
      this.pwdType2 == ''
        ? e.setAttribute('style', 'color: #409EFF')
        : e.setAttribute('style', 'color: #c0c4cc')
    },
    //退出登录
    del() {
      window.sessionStorage.clear()
      //   this.$message({
      //     message: '请重新登录',
      //     type: 'warning',
      //   })
      this.$router.push('/login')
    },
    async changepw() {
      this.$refs.pwref.validate(async (valid) => {
        if (valid) {
          this.pwform.tag = 2
          const { data: res } = await changePw(this.pwform)
          console.log(res)
          if (res.code === 0) {
            this.$message.success('修改密码成功，请重新登录')
            this.closePwDialog()
            this.del()
          } else {
            this.$message.error(res.message)
          }
        }
      })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getTableData()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}
.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.info-left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left-bom {
  margin: 8px 0;
  font-size: 14px;
  color: #555;
}
.tab {
  width: 100%;
}
.tab .tab-tit {
  width: 100px;
  font-weight: bold;
}
.tab td {
  padding: 5px;
  vertical-align: baseline;
}
</style>
